<div style="position: relative">
  <!-- the position of the parent container must be set to relative -->
  <!-- It is really important to set loaderId for non-master loader -->
  <ngx-ui-loader bgsPosition="center-center"
  fgsType="three-strings"
  bgsType="three-bounce"
  text="Loading..."
  blur="5"
  bgsColor="#ff7433"
  logoUrl="assets/dashboard/company_logo.png"></ngx-ui-loader>
</div>

<router-outlet></router-outlet>
