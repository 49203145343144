import { Subject, takeUntil, Observable } from 'rxjs';
import { AppService } from './app.service';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{

  title = 'my-app';
  destroy$ = new Subject<void>();
  showProgressBar$: Observable<boolean>;

  constructor(
    private appService: AppService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document){
  }

  ngOnInit(): void {
    this.appService.isDarkMode.pipe(
      takeUntil(this.destroy$)
    ).subscribe(darkMode=>this.setTheme(darkMode));

    // this.appService.isNepali.pipe(
    //   takeUntil(this.destroy$)
    // ).subscribe(isNp=>this.onLangSelect(isNp ? "np" : "en"));

  }

  // onLangSelect(lang: string){
  //   this.translate.use(lang);
  // }

  setTheme(isDark: boolean){
    this.renderer.setAttribute(this.document.body, "class", isDark ? 'theme-dark' : 'theme-light');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
