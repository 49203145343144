import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Injectable(
  {providedIn: 'root'}
)
export class CustomSnackBarService{

  config: MatSnackBarConfig = {
    duration: 1000,
    verticalPosition: 'top'
  }

  constructor(private snackbar: MatSnackBar){

  }

  error(message:string, action?: string){
    this.snackbar.open(message, action, {
      ...this.config,
      panelClass: ['bg-red-600'],
    }, )
  }

  warn(message: string, action?: string){

    this.snackbar.open(message, action, {
      ...this.config,
      duration: 1500,
      panelClass: ['bg-yellow-600', 'text-black'],
  });
  }

  success(message: string, action?:string){
    this.snackbar.open(message, action, {
      ...this.config,
      panelClass: ['bg-green-600']
    })
  }
}
