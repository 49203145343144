export function removeEmptyOrNull(obj: any){

    if (obj) {
        Object.keys(obj).forEach(function (k) {
            if (typeof obj[k] === "object" && obj[k] !== null) {
                Object.keys(obj[k]).forEach(function (l) {
                    if (
                        obj[k][l] === null ||
                        obj[k][l] === undefined ||
                        obj[k][l] === ""
                    ) {
                        delete obj[k][l];
                    }
                    if (Array.isArray(obj[k][l]) && obj[k][l].length == 0) {
                        delete obj[k][l];
                    }
                });
            }
            if (obj[k] === null || obj[k] === undefined || obj[k] === "") {
                delete obj[k];
            }
            if (Array.isArray(obj[k]) && obj[k].length == 0) {
                delete obj[k];
            }
        });
    }
    return obj;
}

