import { JwtHelperService } from '@auth0/angular-jwt';
import { RestResponse } from './app.model';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { User } from './dashboard/users/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  API_URL = environment.apiUrl;
  private jwtHelper: JwtHelperService;

  private permissions: string[] = [];

  constructor(private http: HttpClient) {
    this.jwtHelper = new JwtHelperService();
  }

  get token(): string {
    return localStorage.getItem('access_token');
  }

  get user(): User {
    return JSON.parse(localStorage.getItem('user'));
  }

  private set user(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  isAdmin() {
    return this.roles == 'ADMIN';
  }
  private set token(token: string) {
    localStorage.setItem('access_token', token);
  }

  forgotPassword(username: string): Observable<RestResponse> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('Content-Type', 'application/json');

    return this.http.post<RestResponse>(
      `${this.API_URL}forgot-password/${username}`,
      null,
      {
        headers: headers,
      }
    );
  }
  resetForgotPassword(reset: {
    token: string;
    password: string;
  }): Observable<RestResponse> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('Content-Type', 'application/json');

    return this.http.post<RestResponse>(
      this.API_URL + 'reset-password',
      reset,
      {
        headers: headers,
      }
    );
  }

  isTokenExpired(): boolean {
    return this.jwtHelper.isTokenExpired(this.token);
  }

  get roles(): string {
    return this.jwtHelper.decodeToken(this.token)['scope'];
  }

  get loggedInUserName() {
    return this.jwtHelper.decodeToken(this.token)['sub'];
  }

  hasPermission(permission: string) {
    const index = this.permissions.findIndex((perm) => perm == permission);
    return index != -1;
  }

  public login(username: string, password: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true',
        Authorization: 'Basic ' + btoa(`${username}:${password}`),
      }),
    };
    return this.http
      .post<RestResponse>(`${this.API_URL}token`, null, httpOptions)
      .pipe(
        tap((result) => {
          this.token = result.access_token;
          this.user = result.user;
        })
      );
  }
}
export interface Token {
  scope: string;
}
