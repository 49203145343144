import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { EMPTY, Observable, catchError, map } from 'rxjs';
import { AuthService } from './app/auth.service';
import { CustomSnackBarService } from './app/shared/custom.snackbar.service';
import { RestResponse } from './app/app.model';
import { Router } from '@angular/router';
import { removeEmptyOrNull } from './app/utils';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private snackbar: CustomSnackBarService,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    //skip some endpoint token
    const skipIntercept = request.headers.has('skip');

    let customized = request.headers;

    if (!skipIntercept && this.authService.token) {
      customized = new HttpHeaders({
        Authorization: `Bearer ${this.authService.token}`,
        'Content-Type': 'application/json',
      });
    } else {
      customized.delete('skip');
    }

    request = request.clone({
      headers: customized,
      body: removeEmptyOrNull(request.body),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const response = event.body as RestResponse;
          if (response.statusCode == 200) {
            event = event.clone({
              body: response.body,
            });
            this.snackbar.success(response?.message);
          } else {
            this.snackbar.error(response?.message);
          }
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400: {
            this.snackbar.warn(error.error?.Errors[0]);
            break;
          }

          case 401: {
            this.snackbar.error(
              'Couldnt authenticate user... Please login again',
            );
            this.router.navigate(['/login']);
            break;
          }

          case 502: {
            this.snackbar.error('Server is down or restarting........');
            break;
          }

          case 500:
            this.snackbar.error('Sorry for inconvenience sth went bad....');
            break;
          default: {
            console.error('error while making http call ', error);
            this.snackbar.warn(error.error);
          }
        }

        return EMPTY;
      }),
    );
  }
}
