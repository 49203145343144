import { AuthService } from './../auth.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CustomSnackBarService } from '../shared/custom.snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
    private snackbar: CustomSnackBarService
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this.authService.isTokenExpired()) {
      this.snackbar.error('Session Expired!! Please login again');
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isTokenExpired()) {
      this.snackbar.error('Session Expired!! Please login again');
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
